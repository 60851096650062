@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg class='w-6 h-6' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='gainsboro' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18 17.94 6M18 18 6.06 6'/%3E%3C/svg%3E%0A");
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

input[type='search']::-webkit-search-cancel-button:hover {
    transform: rotate(90deg);
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: rgb(63 63 70);
}

::-webkit-scrollbar-thumb {
    background: rgb(250 250 250);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(161 161 170);
}

html {
    scrollbar-gutter: stable;
}
